<template>
  <div class="page-rooter-fff">
    <nav-bar v-if="!noHeader" :headerTitle="title" :showBack="true" :header-back="appBack"></nav-bar>
    <app-page>
      <div class="content" v-html="content"></div>
    </app-page>

  </div>
</template>

<script>
import appMixin from '@/mixin/appMixin'
import {hideAppBackBtn, appBack} from "@/lib/appMethod";
import navBar from '@/components/nav-bar/nav-bar'
import appPage from '@/common/components/appPage'
import { getParam } from '@/lib/utils'
import axios from "axios";

export default {
  data() {
    return {
      title: '',
      content: '',
      key: '', // 文章唯一标识
      noHeader: 1,
    };
  },
  mixins: [appMixin],
  components: {
    navBar,
    appPage,
  },
  created() {
    this.key = getParam().key
    this.noHeader = getParam().noHeader

    try {
      hideAppBackBtn()
    } catch (e) {}

    this.getInfo()
  },
  methods: {
    getInfo() {

      axios({ // 该接口太老 返回格式不一致 所以单独写了
        headers: {
          "version": 1,
          "Content-Type": "application/json;charset=utf-8",
        },
        method: 'get',
        url: this.baseURL + `/article/getContent?id=${this.key}`,
      }).then(res => {
        this.content = res.data.content
        this.title = res.data.title
        document.title = res.data.title
      }).catch(error => {
        reject(error);
      });
    },
    appBack() {
      appBack();
    },
  },
};
</script>

<style lang="less" scoped>
  .page-rooter-fff {
    padding: 32px 0;
    h2 {
      width: 100%;
      text-align: center;
      margin: 0 0 56px;
    }
    .content {
      box-sizing: border-box;
      width: 100%;
      padding: 0 32px;
      font-size: 32px;
    }
  }
</style>
